import { HttpClient, HttpHeaders } from "@angular/common/http";
import { computed, DestroyRef, EventEmitter, inject, Injectable, signal } from "@angular/core";
import { Observable } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AbstractControl, FormArray, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import { AttachmentType } from "@app/pages/financials/shared/const/attachment-type.const";
import { environment } from "@environments/environment";
import { iconLib } from "@app/core/const/roam-icon";
import { RoamToastrService } from "@app/pages/task/+data-access";
import { IConfirmationDialog } from "@app/shared/interfaces/confirmation-dialog.interface";
import { confirmExit } from "@app/pages/settings-global/permissions/+data-access/const";
import { ConfirmationDialogComponent } from "@app/shared/components/roam-dialog/confirmation-dialog/confirmation-dialog.component";
import { dialogConfig } from "@app/core/const/dialog.const";

@Injectable({
    providedIn: 'root',
})
export class GlobalFormStore {
    iconLib = signal<any>(iconLib);
    isLast = signal<boolean>(false);
    isSubmit = signal<boolean>(false);
    isDirty = signal<boolean>(false);
    isHaveAccountInfo = signal<boolean>(true);
    propertyId = signal<string>('');
    modelId = signal<string>('');
    attachmentModel = signal<AttachmentType | string>('');
    modelConfig = computed(() => {
        return {
            propertyId: this.propertyId()!,
            modelId: this.modelId(),
            model: this.attachmentModel(),
        };
    });
    formData = {
        customer: {
            pets: signal<any[]>([]),
            vehicles: signal<any[]>([]),
        },
        emergency: signal<any[]>([]),
        property: signal<any[]>([]),
        addresses: signal<any[]>([]),
    }

    #dialog = inject(MatDialog);
    
    readonly http = inject(HttpClient);
    readonly destroyRef = inject(DestroyRef);
    readonly toastr = inject(RoamToastrService);
    readonly userConfig = inject(UserConfigStore);

    // Tab Menu Configuration
    associationTabMenu = signal<string | undefined>(undefined);
    
    constructor() {
        this.userConfig.selectedAssociationId$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(resp => {
                this.propertyId.set(resp || "");
            });
    }

    getControl(control: AbstractControl): AbstractControl {
        return control;
    }

    getFormArray(formArray: FormArray): FormGroup[] {
        return formArray.controls as FormGroup[];
    }

    addControl(formArray: FormArray, initFunction: any): void {
        formArray.push(initFunction());
    }

    removeControl(formArray: FormArray, index: number): void {
        formArray.removeAt(index);
    }

    isValid(control: AbstractControl): boolean {
        if (!control) return false;

        return control.invalid && (control.dirty || control.touched);
    }

    uploadFileService(file: File): Observable<any> {
        const headers = new HttpHeaders();
        headers.append("Content-Type", "multipart/form-data");
        headers.append("Accept", "application/json");

        const body = new FormData();
        body.append("modelId", this.modelConfig().modelId);
        body.append("modelType", this.modelConfig().model);
        body.append("propertyId", this.modelConfig().propertyId);
        body.append("attachments[]", file, file.name);

        return this.http.post<any[]>(`${environment.apiUrl}/attachments`, body, {
            withCredentials: true,
            headers,
        }).pipe(takeUntilDestroyed(this.destroyRef));
    }

    deleteFile(id: string): Observable<any> {
        type Resp = { message: string };

        return this.http.delete<Resp>(`${environment.apiUrl}/attachments/${id}`, {
            withCredentials: true,
        }).pipe(takeUntilDestroyed(this.destroyRef));
    }

    formIsDirty(form: FormGroup): void {
        form.valueChanges.subscribe(() => {
            if (form.dirty) this.isDirty.set(true);
        });
    }

    onSubmit({
        form,
        onSave,
        props
    }: {
        form: FormGroup,
        onSave: ({ ...args }) => {},
        props: any,
    }): any {
        form.markAllAsTouched();

        if (!form.valid) return;

        onSave({ ...props });
    }

    confirmationDialog(dialogRef: any, isClose: boolean = true, callbackFunc?: VoidFunction, onClose?: EventEmitter<Event>) {
        const confirmationDialogText: IConfirmationDialog = confirmExit;

        this.#dialog
            .open(ConfirmationDialogComponent, {
                ...dialogConfig.confirm,
                data: {
                    confirmation: confirmationDialogText,
                },
            })
            .afterClosed()
            .subscribe(val => {
                if (!val) return;

                this.isDirty.set(false);

                if (callbackFunc) callbackFunc();

                if (onClose) onClose.emit();

                if (isClose) dialogRef.close();
            });
    }
}